export const AUTHORITY_CONFIG = {
    roles: [
      {
        id: 'single_decision_maker',
        label: 'Single Decision Maker',
        details: [
          { id: 'in_meeting', label: 'In Meeting', value: 'single_decision_maker_in_meeting', points: 25 },
          { id: 'not_in_meeting', label: 'Not in Meeting', value: 'single_decision_maker_not_in_meeting', points: 20 }
        ]
      },
      {
        id: 'dual_decision_maker',
        label: 'Dual Decision Maker',
        details: [
          { id: 'both_in_meeting', label: 'Both in Meeting', value: 'dual_decision_maker_both_in_meeting', points: 25 },
          { id: 'one_in_meeting', label: 'One in Meeting', value: 'dual_decision_maker_one_in_meeting', points: 20 }
        ]
      },
      {
        id: 'group_decision_maker',
        label: 'Group Decision Maker',
        details: [
          { id: 'all_in_meeting', label: 'All in Meeting', value: 'group_decision_maker_all_in_meeting', points: 25 },
          { id: 'majority_in_meeting', label: 'Majority in Meeting', value: 'group_decision_maker_majority_in_meeting', points: 20 },
          { id: 'minority_in_meeting', label: 'Minority in Meeting', value: 'group_decision_maker_minority_in_meeting', points: 15 }
        ]
      },
      {
        id: 'technical_decision_maker',
        label: 'Technical Decision Maker (15 points)',
        value: 'technical_decision_maker',
        points: 15
      },
      {
        id: 'ito',
        label: 'ITO (12 points)',
        value: 'ito',
        points: 12
      },
      {
        id: 'regular_user',
        label: 'Regular User (10 points)',
        value: 'regular_user',
        points: 10
      },
      {
        id: 'no_one',
        label: 'No One (0 points)',
        value: 'no_one',
        points: 0
      }
    ]
  };
  
  export const getAuthorityDisplay = (authorityValue) => {
    const authorityDisplayMap = {
      'single_decision_maker_in_meeting': 'Single Decision Maker (In Meeting)',
      'single_decision_maker_not_in_meeting': 'Single Decision Maker (Not in Meeting)',
      'dual_decision_maker_both_in_meeting': 'Dual Decision Maker (Both in Meeting)',
      'dual_decision_maker_one_in_meeting': 'Dual Decision Maker (One in Meeting)',
      'group_decision_maker_all_in_meeting': 'Group Decision Maker (All in Meeting)',
      'group_decision_maker_majority_in_meeting': 'Group Decision Maker (Majority)',
      'group_decision_maker_minority_in_meeting': 'Group Decision Maker (Minority)',
      'technical_decision_maker': 'Technical Decision Maker',
      'ito': 'ITO',
      'regular_user': 'Regular User',
      'no_one': 'No Authority'
    };
    
    return authorityDisplayMap[authorityValue] || authorityValue;
  };
  
  export const getAuthorityScore = (authorityValue) => {
    const authorityScores = {
      'single_decision_maker_in_meeting': 25,
      'single_decision_maker_not_in_meeting': 20,
      'dual_decision_maker_both_in_meeting': 25,
      'dual_decision_maker_one_in_meeting': 20,
      'group_decision_maker_all_in_meeting': 25,
      'group_decision_maker_majority_in_meeting': 20,
      'group_decision_maker_minority_in_meeting': 15,
      'technical_decision_maker': 15,
      'ito': 12,
      'regular_user': 10,
      'no_one': 0
    };
  
    return authorityScores[authorityValue] || 0;
  };