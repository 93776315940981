import React from 'react';
import { Link } from 'react-router-dom';

function Header({ user, userRole, handleSignOut }) {
  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center py-4 px-6">
          {/* Logo ve Başlık */}
          <div className="flex items-center space-x-6">
            <Link to="/" className="flex items-center">
              <img src="/images/logo.svg" alt="imba Logo" className="h-10 w-auto" />
            </Link>
            <div className="hidden md:block">
              <h1 className="text-2xl font-bold text-gray-900">BANT Score Calculator</h1>
            </div>
          </div>

          {/* Kullanıcı Bilgileri ve Butonlar */}
          {user && (
            <div className="flex items-center space-x-4">
              {/* Kullanıcı Bilgileri */}
              <div className="hidden md:flex flex-col items-end">
                <span className="text-sm font-medium text-gray-900">
                  {user.email}
                </span>
                <span className="text-xs text-gray-500 capitalize">
                  {userRole} Role
                </span>
              </div>

              {/* Butonlar */}
              <div className="flex items-center space-x-3">
                {userRole === 'admin' && (
                  <Link 
                    to="/admin" 
                    className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium px-4 py-2 rounded-md transition duration-150 ease-in-out"
                  >
                    Admin Panel
                  </Link>
                )}
                <button 
                  onClick={handleSignOut} 
                  className="bg-red-600 hover:bg-red-700 text-white text-sm font-medium px-4 py-2 rounded-md transition duration-150 ease-in-out"
                >
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;