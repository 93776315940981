import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { FirebaseContext } from './FirebaseProvider';
import Loading from './Loading';

function BANTScoreDetail() {
  const { scoreId } = useParams();
  const navigate = useNavigate();
  const { db } = useContext(FirebaseContext);
  const [scoreData, setScoreData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const scoreDoc = await getDoc(doc(db, 'bantScores', scoreId));
        if (scoreDoc.exists()) {
          const data = scoreDoc.data();
          console.log('Score Data:', data);
          setScoreData(data);
        } else {
          setError("No such document!");
        }
      } catch (err) {
        console.error("Error fetching score data:", err);
        setError("An error occurred while fetching the score data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [db, scoreId]);
  if (loading) {
    return <Loading text="Loading score details..." />;
  }
  
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500 bg-red-100 px-6 py-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }
  const getBudgetScore = () => {
    if (!scoreData) return 0;

    const budgetScores = {
      'Over budget': 25,       // %100+ bütçe
      'Equivalent budget': 20, // %60-79 bütçe
      'Medium budget': 15,     // %40-59 bütçe
      'Low budget': 5,        // %20-39 bütçe
      'No budget': 0          // %0 bütçe
    };

    let score = budgetScores[scoreData.budgetStatus] || 0;
    if (scoreData.budgetStatus !== 'Over budget' && scoreData.hasServiceBudget) {
      score = Math.min(score + 5, 25);
    }
    return score;
  };
  const handleEdit = () => {
    navigate(`/edit-bant/${scoreId}`);  // Doğru route'a yönlendirme
  };
  const getAuthorityScore = () => {
    if (!scoreData) return 0;
  
    const authorityScores = {
      'single_decision_maker_in_meeting': 25,
      'single_decision_maker_not_in_meeting': 20,
      'dual_decision_maker_both_in_meeting': 25,
      'dual_decision_maker_one_in_meeting': 20,
      'group_decision_maker_all_in_meeting': 25,
      'group_decision_maker_majority_in_meeting': 20,
      'group_decision_maker_minority_in_meeting': 15,
      'technical_decision_maker': 15,
      'ito': 12,
      'regular_user': 10,
      'no_one': 0
    };
  
    return authorityScores[scoreData.authority] || 0;
  };
  const getAuthorityDisplay = (authorityValue) => {
    const authorityDisplayMap = {
      'single_decision_maker_in_meeting': 'Single Decision Maker (In Meeting)',
      'single_decision_maker_not_in_meeting': 'Single Decision Maker (Not in Meeting)',
      'dual_decision_maker_both_in_meeting': 'Dual Decision Maker (Both in Meeting)',
      'dual_decision_maker_one_in_meeting': 'Dual Decision Maker (One in Meeting)',
      'group_decision_maker_all_in_meeting': 'Group Decision Maker (All in Meeting)',
      'group_decision_maker_majority_in_meeting': 'Group Decision Maker (Majority)',
      'group_decision_maker_minority_in_meeting': 'Group Decision Maker (Minority)',
      'technical_decision_maker': 'Technical Decision Maker',
      'ito': 'ITO',
      'regular_user': 'Regular User',
      'no_one': 'No Authority'
    };
  
    return authorityDisplayMap[authorityValue] || authorityValue;
  };
  const getNeedScore = () => {
    if (!scoreData || !scoreData.need) return 0;
    return Math.min(scoreData.need.length * 5, 25);
  };

  const getTimelineScore = () => {
    if (!scoreData) return 0;

    const timelineScores = {
      'immediate': 25,
      'this month': 20,
      'this quarter': 15,
      'next quarter': 10,
      'this year': 5,
      'undefined': 0
    };
    return timelineScores[scoreData.timeline.toLowerCase()] || 0;
  };

  const renderSummaryScores = () => {
    if (!scoreData) return null;
  
    const summaryData = [
      { 
        label: 'Budget', 
        score: getBudgetScore(),
        description: (
          <>
            {scoreData.budgetStatus}
            <span className="block mt-1">
              {scoreData.hasServiceBudget ? '+ Service Budget' : 'No Service Budget'}
            </span>
          </>
        )
      },
      { 
        label: 'Authority', 
        score: getAuthorityScore(),
        description: getAuthorityDisplay(scoreData.authority).split('(').map((part, index) => 
          index === 0 ? part : <span key={index} className="block mt-1">({part}</span>
        )
      },
      { 
        label: 'Need', 
        score: getNeedScore(),
        description: (
          <>
            {`${scoreData.need?.length || 0} needs identified`}
            <span className="block mt-1 text-xs">
              {scoreData.need?.join(', ')}
            </span>
          </>
        )
      },
      { 
        label: 'Timeline', 
        score: getTimelineScore(),
        description: scoreData.timeline
      }
    ];

  

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {summaryData.map(({ label, score, description }) => (
          <div key={`summary-${label.toLowerCase()}`} 
               className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg transform hover:-translate-y-1">
            <div className="text-center">
              <h4 className="text-lg font-semibold text-gray-700">{label}</h4>
              <div className="mt-2 text-3xl font-bold text-blue-600">{score}<span className="text-lg text-gray-500">/25</span></div>
              <p className="mt-2 text-sm text-gray-500">{description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderQualityIndicator = () => {
    const qualityColors = {
      'Excellent': 'bg-purple-100 text-purple-800 border-purple-200',
      'Very High': 'bg-blue-100 text-blue-800 border-blue-200',
      'High': 'bg-green-100 text-green-800 border-green-200',
      'Medium': 'bg-yellow-100 text-yellow-800 border-yellow-200',
      'Low': 'bg-orange-100 text-orange-800 border-orange-200',
      'Very Low': 'bg-red-100 text-red-800 border-red-200'
    };

    return (
      <div className={`inline-flex items-center px-4 py-2 rounded-full border ${qualityColors[scoreData.quality]}`}>
        <span className="text-sm font-medium">{scoreData.quality}</span>
      </div>
    );
  };

  const renderDetailSection = (title, content) => (
    <div className="bg-white rounded-lg shadow-md mb-6 overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      </div>
      <div className="p-6">
        {content}
      </div>
    </div>
  );

  const renderNeedsList = () => {
    if (!scoreData?.need?.length) return <p className="text-gray-500">No needs identified</p>;

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {scoreData.need.map((need, index) => (
          <div key={index} className="flex items-center space-x-2">
            <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
            <span className="text-gray-700">{need}</span>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return <Loading text="Loading score details..." />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500 bg-red-100 px-6 py-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  if (!scoreData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-500">No data available</div>
      </div>
    );
  }

  return (
    <div className="content-container">
      <div className="mb-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">BANT Score Analysis</h1>
            <p className="text-gray-600">Domain: {scoreData?.domainName}</p>
            {scoreData?.bantDocLink && (
              <a 
                href={scoreData.bantDocLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="inline-flex items-center text-blue-600 hover:text-blue-800 mt-1"
              >
                <span>View BANT Document</span>
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
            )}
          </div>
          <div className="mt-4 md:mt-0 flex items-center space-x-4">
            <div className="flex items-center space-x-3">
              <span className="text-sm text-gray-500">Total Score:</span>
              <span className="text-2xl font-bold text-gray-900">{scoreData?.score}</span>
              {renderQualityIndicator()}
            </div>
            <button 
              onClick={handleEdit}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
              Edit
            </button>
          </div>
        </div>
      </div>
      {/* Summary Scores */}
      {renderSummaryScores()}

      {/* Budget Details */}
      {renderDetailSection('Budget Analysis', (
        <div className="space-y-4">
          <div className="flex justify-between items-center pb-3 border-b">
            <span className="text-gray-600">Budget Status</span>
            <span className="font-medium">{scoreData.budgetStatus}</span>
          </div>
          <div className="flex justify-between items-center pb-3 border-b">
            <span className="text-gray-600">Service Budget</span>
            <span className="font-medium">{scoreData.hasServiceBudget ? 'Yes' : 'No'}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Budget Score</span>
            <span className="font-medium">{getBudgetScore()}/25</span>
          </div>
        </div>
      ))}

      {/* Authority Details */}
      {renderDetailSection('Authority Analysis', (
    <div className="space-y-4">
      <div className="flex justify-between items-center pb-3 border-b">
        <span className="text-gray-600">Authority Level</span>
        <span className="font-medium">{getAuthorityDisplay(scoreData.authority)}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Authority Score</span>
        <span className="font-medium">{getAuthorityScore()}/25</span>
      </div>
    </div>
  ))}

      {/* Need Details */}
      {renderDetailSection('Need Analysis', (
        <div className="space-y-4">
          <div className="pb-3 border-b">
            <div className="text-gray-600 mb-3">Identified Needs</div>
            {renderNeedsList()}
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Need Score</span>
            <span className="font-medium">{getNeedScore()}/25</span>
          </div>
        </div>
      ))}

      {/* Timeline Details */}
      {renderDetailSection('Timeline Analysis', (
        <div className="space-y-4">
          <div className="flex justify-between items-center pb-3 border-b">
            <span className="text-gray-600">Timeline</span>
            <span className="font-medium">{scoreData.timeline}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Timeline Score</span>
            <span className="font-medium">{getTimelineScore()}/25</span>
          </div>
        </div>
      ))}

      {/* Back Button */}
      <div className="mt-8">
        <Link 
          to="/" 
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Back to Calculator
        </Link>
      </div>
    </div>
  );
}

export default BANTScoreDetail;