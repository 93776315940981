import React from 'react';

function Loading({ text = 'Loading...', fullScreen = false }) {
  const containerClasses = fullScreen 
    ? 'fixed inset-0 bg-white/80 backdrop-blur-sm z-50'
    : 'min-h-[400px]';

  return (
    <div className={`flex flex-col items-center justify-center ${containerClasses}`}>
      <div className="relative">
        {/* Ana loading dairesi */}
        <div className="w-16 h-16 rounded-full border-4 border-blue-100">
          <div className="w-16 h-16 rounded-full border-4 border-blue-500 border-t-transparent animate-spin"></div>
        </div>
        
        {/* İç pulsing efekti */}
        <div className="absolute top-0 left-0 w-16 h-16 rounded-full animate-pulse bg-blue-500/10"></div>
      </div>
      
      {/* Loading yazısı */}
      <div className="mt-4 text-blue-600 font-medium animate-pulse">
        {text}
      </div>
    </div>
  );
}

export default Loading;