import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { VERSION } from './Version';
import ChangelogModal from './ChangelogModal';

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changelogContent, setChangelogContent] = useState('');

  const fetchChangelog = async () => {
    try {
      // Public dizininden README.md dosyasını oku
      const response = await fetch('/README.md');
      if (!response.ok) {
        throw new Error('Failed to fetch changelog');
      }
      const content = await response.text();
      setChangelogContent(content);
    } catch (error) {
      console.error('Error fetching changelog:', error);
      setChangelogContent('Error loading changelog content. Please try again later.');
    }
  };

  const handleVersionClick = async (e) => {
    e.preventDefault();
    if (!changelogContent) {
      await fetchChangelog();
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <footer className="bg-gray-200 p-4 text-center">
        <p>
          © {new Date().getFullYear()} imba BANT Score Calculator App - 
          <button 
            onClick={handleVersionClick}
            className="ml-1 text-blue-600 hover:text-blue-800 underline"
          >
            Version {VERSION}
          </button>
        </p>
      </footer>

      <ChangelogModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={changelogContent}
      />
    </>
  );
}

export default Footer;