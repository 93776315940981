import React, { useState, useEffect, useCallback, useContext } from 'react';
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { FirebaseContext } from './FirebaseProvider';
import { handleError } from './errorHandlingUtils';
import { calculateBANTScore } from '../utils/bantCalculations';
import Loading from './Loading';

// Authority configuration
const authorityConfig = {
  roles: [
    {
      id: 'single_decision_maker',
      label: 'Single Decision Maker',
      details: [
        { id: 'in_meeting', label: 'In Meeting', value: 'single_decision_maker_in_meeting', points: 25 },
        { id: 'not_in_meeting', label: 'Not in Meeting', value: 'single_decision_maker_not_in_meeting', points: 20 }
      ]
    },
    {
      id: 'dual_decision_maker',
      label: 'Dual Decision Maker',
      details: [
        { id: 'both_in_meeting', label: 'Both in Meeting', value: 'dual_decision_maker_both_in_meeting', points: 25 },
        { id: 'one_in_meeting', label: 'One in Meeting', value: 'dual_decision_maker_one_in_meeting', points: 20 }
      ]
    },
    {
      id: 'group_decision_maker',
      label: 'Group Decision Maker',
      details: [
        { id: 'all_in_meeting', label: 'All in Meeting', value: 'group_decision_maker_all_in_meeting', points: 25 },
        { id: 'majority_in_meeting', label: 'Majority in Meeting', value: 'group_decision_maker_majority_in_meeting', points: 20 },
        { id: 'minority_in_meeting', label: 'Minority in Meeting', value: 'group_decision_maker_minority_in_meeting', points: 15 }
      ]
    },
    {
      id: 'technical_decision_maker',
      label: 'Technical Decision Maker (15 points)',
      value: 'technical_decision_maker',
      points: 15
    },
    {
      id: 'ito',
      label: 'ITO (12 points)',
      value: 'ito',
      points: 12
    },
    {
      id: 'regular_user',
      label: 'Regular User (10 points)',
      value: 'regular_user',
      points: 10
    },
    {
      id: 'no_one',
      label: 'No One (0 points)',
      value: 'no_one',
      points: 0
    }
  ]
};

const budgetOptions = [
  { value: 'Over budget', label: 'Over budget (25 points)' },
  { value: 'Equivalent budget', label: 'Equivalent budget (20 points)' },
  { value: 'Medium budget', label: 'Medium budget (15 points)' },
  { value: 'Low budget', label: 'Low budget (5 points)' },
  { value: 'No budget', label: 'No budget (0 points)' }
];

const timelineOptions = [
  { value: 'immediate', label: 'Immediate (25 points)' },
  { value: 'next month', label: 'Next Month (20 points)' },
  { value: 'this quarter', label: 'This Quarter (15 points)' },
  { value: 'next quarter', label: 'Next Quarter (10 points)' },
  { value: 'this year', label: 'This Year (5 points)' },
  { value: 'undefined', label: 'Undefined (0 points)' }
];

const needOptions = [
  'Gmail',
  'Drive',
  'Meet',
  'Calendar',
  'Chat',
  'Docs / Sheets / Slides',
  'Appsheet',
  'Gemini',
  'Cloud Collaboratory',
  'Security'
];

function BANTForm({ editingScore, onScoreAdded, onCancelEdit }) {
  const { db, user } = useContext(FirebaseContext);
  const [selectedRole, setSelectedRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    domainName: '',
    bantDocLink: '',
    budgetStatus: '',
    authority: '',
    need: [],
    timeline: '',
    hasServiceBudget: false,
  });

  useEffect(() => {
    if (editingScore) {
      setFormData({
        domainName: editingScore.domainName || '',
        bantDocLink: editingScore.bantDocLink || '',
        budgetStatus: editingScore.budgetStatus || '',
        authority: editingScore.authority || '',
        need: editingScore.need || [],
        timeline: editingScore.timeline || '',
        hasServiceBudget: editingScore.hasServiceBudget || false,
      });

      // Find and set the role from authority value
      const role = authorityConfig.roles.find(role => 
        role.value === editingScore.authority || 
        (role.details && role.details.some(detail => detail.value === editingScore.authority))
      );
      if (role) {
        setSelectedRole(role.id);
      }
    }
  }, [editingScore]);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  }, []);

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    const selectedRoleConfig = authorityConfig.roles.find(role => role.id === roleId);
    
    setSelectedRole(roleId);

    // If selected role has no details (single option)
    if (!selectedRoleConfig?.details) {
      setFormData(prev => ({
        ...prev,
        authority: selectedRoleConfig.value
      }));
    } else {
      // If role has details, clear authority until detail is selected
      setFormData(prev => ({
        ...prev,
        authority: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { score, quality, scoreBreakdown } = calculateBANTScore(
        formData.budgetStatus,
        formData.authority,
        formData.need,
        formData.timeline,
        formData.hasServiceBudget
      );

      const scoreData = {
        ...formData,
        score,
        quality,
        scoreBreakdown,
        userId: user.uid,
        createdAt: new Date()
      };

      if (editingScore) {
        await updateDoc(doc(db, 'bantScores', editingScore.id), {
          ...scoreData,
          updatedAt: new Date()
        });
      } else {
        await addDoc(collection(db, 'bantScores'), scoreData);
      }
      onScoreAdded();
    } catch (error) {
      console.error('Submit Error:', error);
      handleError(error, 'firestore');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto mt-8">
      {loading && <Loading text="Saving BANT score..." fullScreen />}
      
      <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg">
        {/* Budget Section */}
        <div className="bant-section budget-section">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-blue-800">
              <span className="text-3xl">B</span>udget
            </h3>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Domain Name
                </label>
                <input
                  type="text"
                  name="domainName"
                  value={formData.domainName}
                  onChange={handleChange}
                  className="form-input w-full"
                  placeholder="example.com"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  BANT Document Link
                </label>
                <input
                  type="url"
                  name="bantDocLink"
                  value={formData.bantDocLink}
                  onChange={handleChange}
                  className="form-input w-full"
                  placeholder="https://docs.google.com/..."
                />
              </div>
            </div>

            <div className="mt-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Budget Status
              </label>
              <select
                name="budgetStatus"
                value={formData.budgetStatus}
                onChange={handleChange}
                className="form-select w-full"
                required
              >
                <option value="">Select Budget Status</option>
                {budgetOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-4">
              <label className="flex items-center space-x-3 cursor-pointer">
                <input
                  type="checkbox"
                  name="hasServiceBudget"
                  checked={formData.hasServiceBudget}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-blue-600 rounded"
                />
                <span className="text-gray-700">
                  Has Service Budget (Additional 5 points if not Over budget)
                </span>
              </label>
            </div>
          </div>
        </div>

        {/* Authority Section */}
        <div className="bant-section authority-section">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-green-800">
              <span className="text-3xl">A</span>uthority
            </h3>
          </div>
          <div className="p-6 space-y-4">
            {/* Role Selection */}
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Decision Maker Role
              </label>
              <select
                value={selectedRole}
                onChange={handleRoleChange}
                className="form-select w-full"
                required
              >
                <option value="">Select Role</option>
                {authorityConfig.roles.map(role => (
                  <option key={role.id} value={role.id}>
                    {role.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Detail Selection */}
            {selectedRole && authorityConfig.roles.find(r => r.id === selectedRole)?.details && (
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Meeting Status
                </label>
                <select
                  name="authority"
                  value={formData.authority}
                  onChange={handleChange}
                  className="form-select w-full"
                  required
                >
                  <option value="">Select Status</option>
                  {authorityConfig.roles
                    .find(role => role.id === selectedRole)
                    ?.details.map(detail => (
                      <option key={detail.id} value={detail.value}>
                        {detail.label} ({detail.points} points)
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Need Section */}
        <div className="bant-section need-section">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-yellow-800">
              <span className="text-3xl">N</span>eed
            </h3>
            <p className="text-sm text-gray-600 mt-1">
              Each need is worth 5 points (Maximum 25 points)
            </p>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {needOptions.map((option) => (
                <label key={option} className="flex items-center space-x-3 p-3 bg-white rounded-md shadow-sm hover:bg-gray-50">
                  <input
                    type="checkbox"
                    name="need"
                    value={option}
                    checked={formData.need.includes(option)}
                    onChange={(e) => {
                      const updatedNeed = e.target.checked
                        ? [...formData.need, option]
                        : formData.need.filter((item) => item !== option);
                      setFormData(prevData => ({ ...prevData, need: updatedNeed }));
                    }}
                    className="form-checkbox h-5 w-5 text-yellow-600"
                  />
                  <span className="text-gray-700">{option} (5 points)</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        {/* Timeline Section */}
        <div className="bant-section timeline-section">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-red-800">
              <span className="text-3xl">T</span>imeline
            </h3>
          </div>
          <div className="p-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Timeline
              </label>
              <select
                name="timeline"
                value={formData.timeline}
                onChange={handleChange}
                className="form-select w-full"
                required
              >
                <option value="">Select Timeline</option>
                {timelineOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Form Actions */}
        <div className="px-6 py-4 bg-gray-50 rounded-b-lg flex justify-end space-x-4">
          {editingScore && (
            <button
              type="button"
              onClick={onCancelEdit}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          )}
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {editingScore ? 'Update' : 'Calculate'} BANT Score
          </button>
        </div>
      </form>
    </div>
  );
}

export default BANTForm;