import React, { useState, useEffect, useContext, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { collection, getDocs, query, where, setDoc, doc } from 'firebase/firestore';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { FirebaseProvider, FirebaseContext } from './components/FirebaseProvider';
import Login from './components/Login';
import NewUser from './components/NewUser';
import Member from './components/Member';
import Admin from './components/Admin';
import Header from './components/Header';
import Footer from './components/Footer';
import BANTScoreDetail from './components/BANTScoreDetail';
import BANTFormPage from './components/BANTFormPage';
import './App.css';
import './index.css';

function App() {
  const { auth, db } = useContext(FirebaseContext);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState('new');
  const [loading, setLoading] = useState(true);

  const getUserRole = useCallback(async (uid) => {
    const userQuery = query(collection(db, 'users'), where('uid', '==', uid));
    const userSnapshot = await getDocs(userQuery);

    if (userSnapshot.empty) {
      await setDoc(doc(db, 'users', uid), { uid, role: 'new' });
      return 'new';
    } else {
      return userSnapshot.docs[0].data().role;
    }
  }, [db]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const role = await getUserRole(user.uid);
        setUserRole(role);
      } else {
        setUser(null);
        setUserRole('new');
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth, getUserRole]);

  const handleSignIn = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      const role = await getUserRole(result.user.uid);
      setUserRole(role);
    } catch (error) {
      console.error('Error signing in:', error);
    }
  }, [auth, getUserRole]);

  const handleSignOut = useCallback(() => {
    signOut(auth).then(() => {
      setUser(null);
      setUserRole('new');
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  }, [auth]);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen"> 
      {user && <Header user={user} userRole={userRole} handleSignOut={handleSignOut} />}
      <main className="container mx-auto p-4 flex-grow pt-32"> {/* pt-28'den pt-32'ye değiştirildi */}
        {!user ? (
          <Login handleSignIn={handleSignIn} />
        ) : userRole === 'new' ? (
          <NewUser />
        ) : (
          <Routes>
            <Route path="/" element={<Member user={user} />} />
            <Route path="/add-bant" element={<BANTFormPage user={user} />} />
            <Route path="/edit-bant/:id" element={<BANTFormPage user={user} />} />
            <Route path="/admin" element={
              userRole === 'admin' ? <Admin /> : <Navigate to="/" />
            } />
            <Route path="/score/:scoreId" element={<BANTScoreDetail />} />
          </Routes>
        )}
      </main>
      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <FirebaseProvider>
      <Router>
        <App />
      </Router>
    </FirebaseProvider>
  );
}

export default AppWrapper;