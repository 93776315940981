import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { FirebaseContext } from './FirebaseProvider';
import { handleError } from './errorHandlingUtils';

const getAuthorityDisplay = (authorityValue) => {
  const authorityDisplayMap = {
    'single_decision_maker_in_meeting': 'Single Decision Maker (In Meeting)',
    'single_decision_maker_not_in_meeting': 'Single Decision Maker (Not in Meeting)',
    'dual_decision_maker_both_in_meeting': 'Dual Decision Maker (Both in Meeting)',
    'dual_decision_maker_one_in_meeting': 'Dual Decision Maker (One in Meeting)',
    'group_decision_maker_all_in_meeting': 'Group Decision Maker (All in Meeting)',
    'group_decision_maker_majority_in_meeting': 'Group Decision Maker (Majority)',
    'group_decision_maker_minority_in_meeting': 'Group Decision Maker (Minority)',
    'technical_decision_maker': 'Technical Decision Maker',
    'ito': 'ITO',
    'regular_user': 'Regular User',
    'no_one': 'No Authority'
  };

  return authorityDisplayMap[authorityValue] || authorityValue;
};

const getQualityBadgeColor = (quality) => {
  const colors = {
    'Excellent': 'bg-purple-100 text-purple-800',
    'Very High': 'bg-blue-100 text-blue-800',
    'High': 'bg-green-100 text-green-800',
    'Medium': 'bg-yellow-100 text-yellow-800',
    'Low': 'bg-orange-100 text-orange-800',
    'Very Low': 'bg-red-100 text-red-800'
  };
  return colors[quality] || 'bg-gray-100 text-gray-800';
};

function ScoreList({ scores, onScoreDeleted, showTitle = true }) {
  const { db } = useContext(FirebaseContext);

  const handleDelete = useCallback(async (id) => {
    if (window.confirm('Are you sure you want to delete this score?')) {
      try {
        await deleteDoc(doc(db, 'bantScores', id));
        onScoreDeleted();
      } catch (error) {
        handleError(error, 'firestore');
      }
    }
  }, [db, onScoreDeleted]);

  const renderDocLink = (bantDocLink) => {
    if (!bantDocLink) return '-';
    return (
      <a 
        href={bantDocLink} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-600 hover:text-blue-800 flex items-center"
      >
        <span>BANT doc</span>
        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
        </svg>
      </a>
    );
  };

  return (
    <div className="mt-12">
      {showTitle && <h2 className="text-2xl font-bold mb-6 text-gray-800">BANT Scores</h2>}
      
      <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Domain
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                BANT Doc
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Authority
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Score
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Quality
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Date
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score, index) => (
              <tr 
                key={score.id} 
                className={`hover:bg-gray-50 transition-colors duration-200 ${
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                }`}
              >
                <td className="px-5 py-5 border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-gray-900 whitespace-nowrap">
                        {score.domainName}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200">
                  {renderDocLink(score.bantDocLink)}
                </td>
                <td className="px-5 py-5 border-b border-gray-200">
                  <p className="text-gray-900 whitespace-nowrap">
                    {getAuthorityDisplay(score.authority)}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200">
                  <p className="text-gray-900 whitespace-nowrap font-medium">
                    {score.score}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200">
                  <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getQualityBadgeColor(score.quality)}`}>
                    {score.quality}
                  </span>
                </td>
                <td className="px-5 py-5 border-b border-gray-200">
                  <p className="text-gray-900 whitespace-nowrap">
                    {score.createdAt}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200">
                  <div className="flex items-center space-x-4">
                    <Link 
                      to={`/score/${score.id}`}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      View
                    </Link>
                    <Link 
                      to={`/edit-bant/${score.id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </Link>
                    <button 
                      onClick={() => handleDelete(score.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {scores.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No BANT scores found.
          </div>
        )}
      </div>
    </div>
  );
}

export default ScoreList;