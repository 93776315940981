import React from 'react';

function NewUser() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <p className="mb-4 text-xl">Please wait for admin approval to access the calculator.</p>
    </div>
  );
}

export default NewUser;