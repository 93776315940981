export const handleError = (error, errorType) => {
    console.error(`${errorType} Error:`, error);
    
    // Burada hata tipine göre farklı işlemler yapabilirsiniz
    switch (errorType) {
      case 'auth':
        // Kullanıcıya authentication hatası göster
        alert('Authentication error. Please try again.');
        break;
      case 'firestore':
        // Firestore hatası göster
        alert('There was an error accessing the database. Please try again later.');
        break;
      case 'network':
        // Ağ hatası göster
        alert('Network error. Please check your internet connection and try again.');
        break;
      default:
        // Genel hata mesajı
        alert('An unexpected error occurred. Please try again.');
    }
  }