import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { FirebaseContext } from './FirebaseProvider';
import ScoreList from './ScoreList';
import { handleError } from './errorHandlingUtils';
import Loading from './Loading';

function Member({ user }) {
  const { db } = useContext(FirebaseContext);
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchScores();
  }, []);

  const fetchScores = async () => {
    setLoading(true);
    try {
      const scoresCollection = collection(db, 'bantScores');
      const q = query(scoresCollection, orderBy('createdAt', 'desc'));
      const scoresSnapshot = await getDocs(q);
      const scoresList = scoresSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toISOString().split('T')[0]
      }));
      setScores(scoresList);
    } catch (error) {
      handleError(error, 'firestore');

    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Loading text="Loading BANT scores..." />;
  }

  return (
    <div className="content-container">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">BANT Scores</h2>
        <Link 
          to="/add-bant" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150"
        >
          Add new BANT
        </Link>
      </div>
      <ScoreList 
        scores={scores}
        onScoreDeleted={fetchScores}
        showTitle={false}
      />
    </div>
  );
}

export default Member;