import React, { createContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firebaseConfig } from '../firebaseConfig';

export const FirebaseContext = createContext(null);

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // Kullanıcı oturum açtığında Firestore'da kullanıcı belgesini güncelle
        const userRef = doc(db, 'users', authUser.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          // Kullanıcı zaten var, email'i güncelle
          await setDoc(userRef, { email: authUser.email }, { merge: true });
        } else {
          // Yeni kullanıcı, tüm bilgileri kaydet
          await setDoc(userRef, {
            uid: authUser.uid,
            email: authUser.email,
            role: 'new'
          });
        }
        
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const firebase = {
    app,
    db,
    auth,
    user
  };

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};