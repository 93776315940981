// src/utils/bantCalculations.js
import { getAuthorityScore } from './authorityUtils';

export const calculateBANTScore = (budgetStatus, authority, need, timeline, hasServiceBudget) => {
  // Budget Score
  const budgetScore = getBudgetScore(budgetStatus, hasServiceBudget);
  
  // Authority Score
  const authorityScore = getAuthorityScore(authority);
  
  // Need Score
  const needScore = Math.min(need.length * 5, 25);
  
  // Timeline Score
  const timelineScore = getTimelineScore(timeline);

  const totalScore = budgetScore + authorityScore + needScore + timelineScore;

  return {
    score: totalScore,
    quality: getQualityLevel(totalScore),
    scoreBreakdown: {
      budgetScore,
      authorityScore,
      needScore,
      timelineScore
    }
  };
};

export const getBudgetScore = (budgetStatus, hasServiceBudget) => {
  const budgetScores = {
    'Over budget': 25,
    'Equivalent budget': 20,
    'Medium budget': 15,
    'Low budget': 5,
    'No budget': 0
  };

  let score = budgetScores[budgetStatus] || 0;
  if (budgetStatus !== 'Over budget' && hasServiceBudget) {
    score = Math.min(score + 5, 25);
  }
  return score;
};

export const getTimelineScore = (timeline) => {
  const timelineScores = {
    'immediate': 25,
    'next month': 20,
    'this quarter': 15,
    'next quarter': 10,
    'this year': 5,
    'undefined': 0
  };
  return timelineScores[timeline.toLowerCase()] || 0;
};

export const getQualityLevel = (score) => {
  if (score >= 90) return 'Excellent';
  if (score >= 80) return 'Very High';
  if (score >= 65) return 'High';
  if (score >= 50) return 'Medium';
  if (score >= 35) return 'Low';
  return 'Very Low';
};