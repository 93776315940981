import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { FirebaseContext } from './FirebaseProvider';
import BANTForm from './BANTForm';
import { handleError } from './errorHandlingUtils';
import { calculateBANTScore } from '../utils/bantCalculations';

function BANTFormPage({ user }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { db } = useContext(FirebaseContext);
  const [editingScore, setEditingScore] = useState(null);
  const [products, setProducts] = useState([]);

  const fetchScore = useCallback(async () => {
    try {
      const scoreDoc = await getDoc(doc(db, 'bantScores', id));
      if (scoreDoc.exists()) {
        setEditingScore({ id: scoreDoc.id, ...scoreDoc.data() });
      } else {
        navigate('/');
      }
    } catch (error) {
      handleError(error, 'firestore');
    }
  }, [db, id, navigate]);

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = collection(db, 'workspaceProducts');
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        price: parseFloat(doc.data().price)
      }));
      setProducts(productsList);
    } catch (error) {
      handleError(error, 'firestore');
    }
  }, [db]);

  useEffect(() => {
    if (id) {
      fetchScore();
    }
    fetchProducts();
  }, [id, fetchScore, fetchProducts]);

  const handleScoreAdded = () => {
    navigate('/');
  };

  const handleCancelEdit = () => {
    navigate('/');
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <BANTForm 
        user={user}
        products={products}
        onScoreAdded={handleScoreAdded}
        calculateBANTScore={calculateBANTScore}
        editingScore={editingScore}
        onCancelEdit={handleCancelEdit}
      />
    </div>
  );
}

export default BANTFormPage;